import {
	RequestDemoButtonV2,
	SignUpButtonV2,
} from "@components/Button_new/Button";
import { TypographyV2 } from "@components/index";
import React, { HTMLProps } from "react";
import * as styles from "./Hero.module.scss";
import classNames from "classnames";

type TextProps = {
	title: string;
	description: string;
	tabletTextAlignment?: "left" | "center";
	heroTextContainerSize?: "default" | "small";
	textButtonsContainerOverride?: string;
	headerTextOverride?: string;
	bodyTextOverride?: string;
	generalSansFont?: boolean;
	descriptionVariant?: "BODY_TEXT_LARGE_V2" | "BODY_TEXT_EXTRA_LARGE";
	wrapperOverride?:string;
};

type HeroProps = TextProps & {
	image: any;
	tabletTextAlignment?: "left" | "center";
	heroTextContainerSize?: "default" | "small";
	textButtonsContainerOverride?: string;
	headerTextOverride?: string;
	bodyTextOverride?: string;
	generalSansFont?: boolean;
	wrapperClassName?:string;
	wrapperOverride?:string;
} & HTMLProps<HTMLDivElement>;

const TextButtonsContainer = (props: TextProps) => {
	return (
		<div
			className={classNames(styles.textButtonsContainer,props?.textButtonsContainerOverride, {
				[styles.tabletAlignmentCenter]:
					props.tabletTextAlignment === "center",
				[styles.textLinesAlignment]:
					props.heroTextContainerSize === "small",
			})}
		>
			<div
				className={classNames(styles.wrapper,props?.wrapperOverride, {
					[styles.wrapperCenter]:
						props.tabletTextAlignment === "center",
				})}
			>
				<TypographyV2
					weight="BOLD"
					variant="HEADING_1"
					color="text-primary"
					className={classNames(
						styles.headerText,
						props?.headerTextOverride,
						{
							[styles.generalSansFont_headerText]:
								!!props?.generalSansFont,
						}
					)}
				>
					{props.title}
				</TypographyV2>
				<TypographyV2
					weight="REGULAR"
					color="text-helper"
					variant={
						props.descriptionVariant ?? "BODY_TEXT_EXTRA_LARGE"
					}
					className={styles.description}
				>
					{props.description}
				</TypographyV2>
			</div>
			<div className={styles.buttons}>
				<RequestDemoButtonV2 className={styles.virtualFreezerButton} text="Request demo"/>
				<SignUpButtonV2 className={styles.virtualFreezerButton} text="Sign up free"/>
			</div>
		</div>
	);
};

export const Hero = (props: HeroProps) => {
	return (
		<div className={classNames(props?.wrapperClassName)}>
			<div className={classNames(styles.hero, props.className)}>
				<TextButtonsContainer
					title={props.title}
					description={props.description}
					tabletTextAlignment={props.tabletTextAlignment}
					heroTextContainerSize={props.heroTextContainerSize}
					textButtonsContainerOverride={
						props?.textButtonsContainerOverride
					}
					headerTextOverride={props?.headerTextOverride}
					bodyTextOverride={props?.bodyTextOverride}
					generalSansFont={props.generalSansFont}
					wrapperOverride={props?.wrapperOverride}
					descriptionVariant={props.descriptionVariant}
				/>
				<div className={classNames(styles.heroImage)}>{props.image}</div>
			</div>
		</div>
	);
};
